import React from 'react';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import * as consts from '../constants';
import styles from './styles';
import RadioGroup from '../RadioGroup';

const Instructions = ({ disabled }) => {
  const { types, aditionalTypes, procedures, actions, aditionalActions, doProcedure } = consts.instructions;
  return (
    <div>
      {types.map(
        el => (
          <Field
            name={`data.instructions.${el.id}`}
            component={RadioGroup}
            options={actions}
            label={el.name}
            disabled={disabled}
            key={el.id}
          />
        )
      )}
      {aditionalTypes.map(
        el => (
          <Field
            name={`data.instructions.${el.id}`}
            component={RadioGroup}
            options={aditionalActions}
            label={el.name}
            disabled={disabled}
            key={el.id}
          />
        )
      )}
      {procedures.map(
        el => (
          <Field
            name={`data.instructions.${el.id}`}
            component={RadioGroup}
            options={doProcedure}
            label={el.name}
            disabled={disabled}
            key={el.id}
          />
        )
      )}
    </div>
  )
}

export default withStyles(styles)(Instructions);