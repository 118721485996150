const style = {
  BtnUserName: {
    width: "auto"
  },
  UserName: {
    fontSize: "15px",
    marginRight: "10px",
    fontWeight: 700,
    textTransform: "capitalize"
  }
}

export default style;