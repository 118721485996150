const styles = {
  radioGroup: {
    root: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      maxWidth: '800px'
    },
    group: {
      width: 'calc((100% - 300px)/3)',
    },
    formLabel: {
      width: '150px'
    }
  }
};

export default styles;