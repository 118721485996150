import React, { useEffect, useState } from 'react';
import { Admin, Resource, fetchUtils } from 'react-admin';
import jsonServerProvider from 'ra-data-json-server';
import UserIcon from '@material-ui/icons/Group';
import { StripeProvider } from 'react-stripe-elements';

import config from './config/';

import { UsersList, UserCreate, UserEdit } from './components/Users/';
import { CaseCreate, CaseEdit } from './components/Cases/';
import { CaseList } from './components/Cases/CaseList';
import LoginPage from './components/Login/';
import AppLayout from './components/AppLayout';

import authProvider from './services/authProvider';

import customTheme from './theme';

import { hasPermission } from './utils/permissions.utils';

const httpClient = (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({
      Accept: 'application/json',
      'Access-Control-Allow-Origin': '*'
    });
  }

  const authToken = localStorage.getItem('authToken');

  if (authToken) {
    options.headers.set('Authorization', `Bearer ${authToken}`);
  }

  return fetchUtils.fetchJson(url, options);
};

const dataProvider = jsonServerProvider(`${config.api}`, httpClient);

const App = () => {

  useEffect(() => {
    fetch(`${config.api}/translations`)
      .then(res => res.json())
      .then(data => {
        localStorage.setItem('translations', JSON.stringify(data));
      });
  }, []);

  return (
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_KEY}>
      <div style={{ minWidth: "320px", overflowX: "scroll" }}>
        <Admin
          theme={customTheme}
          loginPage={LoginPage}
          dataProvider={dataProvider}
          authProvider={authProvider}
          appLayout={AppLayout}
        >
          {permissions => [
            <Resource
              name={config.routes.cases}
              permissions={permissions}
              list={CaseList}
              create={CaseCreate}
              edit={CaseEdit}
            />,
            <Resource
              name={config.routes.users}
              list={hasPermission(permissions, "ADMIN") ? UsersList : null}
              create={UserCreate}
              edit={UserEdit}
              icon={UserIcon}
            />
          ]}
        </Admin>
      </div>
    </StripeProvider>
  );
};

export default App;
