const styles = () => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    width: '50%',
    minWidth: '300px'
  },
  controlLabel: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative'
  },
  ControlLabelNumberInput: {
    position: 'absolute !important',
    bottom: 0,
    maxWidth: '100px',
    left: '100px',    
    marginLeft: '15px',
  }
});

export default styles;