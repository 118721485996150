import React, { useState } from "react";
import { showNotification as showNotificationAction } from 'react-admin';
import { connect } from 'react-redux';
import { CardElement } from 'react-stripe-elements';
import Button from '@material-ui/core/Button';
import styles from "../../style";
import { fetchEntityChanges } from "../../../../../utils";
import config from '../../../../../config';

const createOptions = (fontSize, padding) => {
  return {
    style: {
      base: {
        fontSize,
        color: '#424770',
        letterSpacing: '0.025em',
        fontFamily: 'Source Code Pro, monospace',
        '::placeholder': {
          color: '#aab7c4',
        },
        padding,
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };
};

const handleBlur = () => {
  console.log('[blur]');
};

const handleChange = (change) => {
  console.log('[change]', change);
};

const handleClick = () => {
  console.log('[click]');
};

const handleFocus = () => {
  console.log('[focus]');
};

const handleReady = () => {
  console.log('[ready]');
};

const CardForm = ({ stripe, toggleNewCard, onCancel, getCards, record, cards, showNotification }) => {
  const [cardComplete, setCardComplete] = useState(null);
  const [isDisabled, setIsDisabled] = useState(false);
  const userId = localStorage.getItem('usrId');

  const handleSubmit = ev => {
    ev.preventDefault();
    if (stripe) {
      stripe
        .createToken()
        .then((payload) => {
          const url = `${config.api}/customer/${record.id}/cards`;
          fetchEntityChanges({
            url, method: "POST", newData: { cardToken: payload.token.id }, handleResponse: (res) => {
              console.log(res);
              if (res.raw) {
                console.log(res.raw.message);
                showNotification(res.raw.message, 'warning');
                setIsDisabled(false);
              }

              getCards();
              toggleNewCard(0);
            }
          });
        });
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  return (
    <div>
      <form onSubmit={e => handleSubmit(e)}>
        <label>
          <div style={{ marginBottom: "15px", fontSize: "16px", ...styles.SubTitle }}> Add new card: </div>
          <CardElement
            onBlur={handleBlur}
            onChange={e => {
              handleChange(e);
              setCardComplete(e.complete);

            }}
            onFocus={handleFocus}
            onReady={handleReady}
            {...createOptions(15, 12)}
          />
        </label>
      </form >
      <div style={styles.BottomPanelCentered}>
        <Button disabled={!cardComplete || isDisabled} style={styles.Button} onClick={e => {
          handleSubmit(e);
          setIsDisabled(true);
        }}>Add Card</Button>
        <Button style={styles.Button} disabled={cards.length < 1} onClick={() => toggleNewCard(0)} >Choose existent card</Button>
        <Button style={styles.Button} onClick={() => onCancel(0)} >Cancel</Button>
      </div>

    </div>
  );
}

export default connect(null, {
  showNotification: showNotificationAction,
})(CardForm);
