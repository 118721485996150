import React from 'react';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import { desialMesialConfig } from './config';

export default function Row(props) {
  const { item, tooth, setTooth } = props;

  function handleChange(evt, name, id) {
    const toothArr = [...tooth];
    const object = toothArr.find(x => x.id === id);
    object[name] = evt.target.value;
    setTooth(toothArr);
  }

  const handleCheckChange = (e, id) => {
    const toothArr = [...tooth];
    const object = toothArr.find((x) => x.id === id);

    object.isAttachment = e.target.checked;

    setTooth(toothArr);
  }

  return (
    <tr>
      <td>№ {desialMesialConfig[item.number].nr}</td>
      <td className='ipr__column'>
        <TextField
          id="standard-number"
          className='ipr__input'
          label="Left side step"
          type="number"
          InputLabelProps={{ shrink: tooth[item.id].leftSideStep }}
          value={tooth[item.id].leftSideStep}
          onChange={(e) => handleChange(e, 'leftSideStep', item.id)}
        />

        <TextField
          id="standard-number"
          className='ipr__input'
          InputLabelProps={{ shrink: tooth[item.id].leftSideMm }}
          label="Left side mm"
          type="number"
          value={tooth[item.id].leftSideMm}
          onChange={(e) => handleChange(e, 'leftSideMm', item.id)}
        />
      </td>
      <td className='ipr__column'>
        <TextField
          id="standard-number"
          className='ipr__input'
          label="Right side step"
          type="number"
          InputLabelProps={{ shrink: tooth[item.id].rightSideStep }}
          value={tooth[item.id].rightSideStep}
          onChange={(e) => handleChange(e, 'rightSideStep', item.id)}
        />

        <TextField
          id="standard-number"
          className='ipr__input'
          label="Right side mm"
          type="number"
          InputLabelProps={{ shrink: tooth[item.id].rightSideMm }}
          value={tooth[item.id].rightSideMm}
          onChange={(e) => handleChange(e, 'rightSideMm', item.id)}
        />
      </td>
      <td className="ipr_column">
        <Checkbox
          checked={tooth[item.id].isAttachment}
          onChange={(e) => handleCheckChange(e, item.id)}
        />
      </td>
    </tr>
  )
}
