import React from 'react';
import Button from '@material-ui/core/Button';
import styles from "../../style";
import config from '../../../../../config'

const TotalCount = ({ shipment, setView, onCancel, unlimited, setPendingPayment }) => {
  const paymentHandlerByEnv = () => {
    if(process.env.REACT_APP_THEME === 'sts')
      return {
        title: 'Pay',
        click: setPendingPayment
      }
    return {
      title: 'Pay with card',
      click: () => setView(1)
    }
  }

  return (
    <>
      <div>
        <span style={styles.SubTitle}>
          Aligners count: {shipment.quantity}
        </span>
        <span style={styles.SubTitle}>
          Total: ${unlimited ? config.unlimitedPrice[process.env.REACT_APP_THEME] : shipment.price}
        </span>
      </div>
      <div style={styles.BottomPanel}>
        <Button style={styles.Button} onClick={paymentHandlerByEnv().click}>{paymentHandlerByEnv().title}</Button>
        <Button style={styles.Button} onClick={() => onCancel()}>Cancel</Button>
      </div>
    </>
  )
};

export default TotalCount;