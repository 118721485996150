import React from 'react';
import CheckBox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

export default ({ setReceivePaymentNote, setReceivePaymentStatus }) => {
    return (
        <div>
            <textarea style={{width: '100%', resize: 'none', height: 100}} onChange={setReceivePaymentNote}></textarea>
            <FormControlLabel 
                control={<CheckBox onChange={setReceivePaymentStatus} />}
                label="Successfully"
            />
        </div>
    )
}