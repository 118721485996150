const defaultCase = {
  shipments: [],
  history: [],
  chat: [],
  media: [],
  status: '',
  data: {
    treat: 'BOTH',
    instructions: {
      UPPER_MIDLINE: 'IMPROVE',
      LOWER_MIDLINE: 'IMPROVE',
      OVERJET: 'IMPROVE',
      OVERBITE: 'IMPROVE',
      ARCH_FORM: 'IMPROVE',
      CANINE_RELATIONSHIP: 'IMPROVE',
      MOLAR_RELATIONSHIP: 'MAINTAIN',
      POSTERIOR_CROSSBITE: 'MAINTAIN',
      DIASTEMAS: 'MAINTAIN',
      IPR: 'IF NEEDED',
      ENGAGERS: 'IF NEEDED',
      PROCLINE: 'IF NEEDED',
      EXPAND: 'IF NEEDED',
      DISTALIZE: 'IF NEEDED',
    },
    midline: {
      upper: {},
      lower: {},
    },
    relationship: {
      canine: {},
      molar: {},
    },
    fixedTeeth: [],
    spaceTeeth: [],
    iprTeeth: [],
  },
};

export default defaultCase;
