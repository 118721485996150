import React from 'react';
import { NumberInput } from 'react-admin';
import { Field } from 'redux-form';
import Radio from '@material-ui/core/Radio';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import * as consts from '../constants';
import ControlLabel from '../ControlLabel/';
import styles from './styles';

const Option = withStyles(styles)(({
  classes,
  item,
  direction,
  onChange,
  inputName,
  disabled,
}) => (
    <div className={classes.controlLabel}>
      <ControlLabel
        value={item.id}
        label={item.name}
        labelPlacement="end"
        onChange={onChange}
        control={<Radio checked={direction === item.id} disabled={disabled} color="primary" />}
      />
      {
        (item.id !== "CENTER" && item.id === direction) &&
        <NumberInput
          className={classNames(classes.ControlLabelNumberInput)}
          label="shift"
          source={`${inputName}.shift`}
          disabled={!(direction === item.id) || disabled}
        />
      }
    </div>
  ));

const MidlineComponent = withStyles(styles)(({ input, disabled }) => {
  const onRadioChange = (e) => {
    input.onChange({ ...input.value, direction: e.target.value });
  }

  const isValue = !!input.value;
  return (
    <FormControl component="fieldset" >
      {consts.midline.directions.map(item =>
        <Option
          item={item}
          onChange={onRadioChange}
          direction={isValue ? input.value.direction : null}
          inputName={input.name}
          key={item.id}
          disabled={disabled}
        />)
      }
    </FormControl>
  )
});

export default withStyles(styles)(({ classes, source, label, disabled }) => (
  <div className={classNames(classes.root)}>
    <FormLabel component="legend">{label} </FormLabel>
    <Field name={source} component={MidlineComponent} disabled={disabled} />
  </div>
));