import React, { useEffect } from 'react';
import ScrollToBottom from 'react-scroll-to-bottom';
import { formatDateInHours } from "../../../utils";

function MessageList(props) {
  const messagesEndRef = React.createRef();

  function scrollToBottom() {
    messagesEndRef.current.scrollIntoView({ behavior: "auto" })
  }

  const getRole = role => {
    const roles = ['Provider', 'Case_manager', 'Admin'];
    return roles[role];
  };


  useEffect(() => {
    scrollToBottom()
  });

  return (
    <ScrollToBottom mode="bottom" className="message-list" id='message-list'>
      {!!props.messages.length ? props.messages.map((message) => {
        const id = (new Date(message.created)).getTime();
        return (
          <li key={id}>
            <div className='message-top'>
              <h4 className="message-sender">{getRole(message.role)}</h4>
              <div className="message-day">{formatDateInHours(message.created)}</div>
            </div>
            <p className="message-text"
              style={{ 'backgroundColor': props.colorChange(process.env.REACT_APP_THEME) }}
              dangerouslySetInnerHTML={{
                __html: message.message,
              }}
            ></p>
          </li>
        )
      }
      ) :
        <div className="empty">
          No messages yet
        </div>
      }
      <div ref={messagesEndRef} />
    </ScrollToBottom>
  )
}
export default MessageList;
