import React, { useEffect, useState } from "react";
import {
  List,
  Datagrid,
  TextField,
  EmailField,
  Create,
  TabbedForm,
  FormTab,
  TextInput,
  LongTextInput,
  SelectArrayInput,
  FunctionField,
  ArrayInput,
  SimpleFormIterator,
  Edit,
  required,
  email,
  maxLength,
  BooleanInput,
} from "react-admin";
import { withStyles } from "@material-ui/core/styles";
import { userRoles } from "./constants";
import { hasPermission } from "../../utils/permissions.utils";
import style from "./style";

export const UsersList = (props) => (
  <List {...props}>
    <Datagrid rowClick="edit">
      <TextField source="firstname" />
      <TextField source="lastname" />
      <EmailField source="email" />
      <FunctionField label="Roles" render={(record) => `${record.roles}`} />
    </Datagrid>
  </List>
);

const validatePrefix = [maxLength(1)];

export const UserCreate = withStyles(style)(({ classes, ...props }) => (
  <Create {...props}>
    <TabbedForm>
      <FormTab label="Summary">
        <TextInput source="firstname" validate={required()} />
        <TextInput source="lastname" validate={required()} />
        <TextInput source="prefix" validate={validatePrefix} />
        <TextInput source="email" validate={[required(), email()]} />
        <TextInput
          label="Password"
          source="password"
          type="password"
          validate={required()}
        />
        <LongTextInput
          source="generalRequirements"
          resettable
          style={{ width: "33%" }}
        />
        <SelectArrayInput source="roles" choices={userRoles} />
        <TextInput
          source="notificationEmail"
          validate={[required(), email()]}
          classes={classes}
        />
      </FormTab>
      <FormTab label="addresses">
        <ArrayInput source="addresses" label="" style={{ width: "100%" }}>
          <SimpleFormIterator classes={classes}>
            <TextInput source="name" classes={classes} />
            <TextInput source="country" classes={classes} defaultValue="USA" />
            <TextInput source="state" classes={classes} />
            <TextInput source="city" classes={classes} />
            <TextInput source="street" classes={classes} />
            <TextInput source="zip" classes={classes} />
            <TextInput source="phone" classes={classes} />
          </SimpleFormIterator>
        </ArrayInput>
      </FormTab>
    </TabbedForm>
  </Create>
));

const UserEditTitle = ({ record }) => (
  <span>{`${record.firstname} ${record.lastname}`}</span>
);

export const UserEdit = withStyles(style)((props) => {
  const { permissions, classes, ...rest } = props;

  return (
    <Edit {...rest} title={<UserEditTitle />}>
      <TabbedForm>
        <FormTab label="Summary">
          <TextInput source="firstname" />
          <TextInput source="lastname" />
          {hasPermission(permissions, "ADMIN") ? (
            <TextInput source="prefix" validate={validatePrefix} />
          ) : (
            ""
          )}
          <TextInput source="email" />
          <TextInput
            label="Password"
            autoComplete="new-password"
            source="password"
            type="password"
          />
          <LongTextInput
            source="generalRequirements"
            resettable
            style={{ width: "33%" }}
          />
          {hasPermission(permissions, "ADMIN") ? (
            <SelectArrayInput source="roles" choices={userRoles} />
          ) : (
            ""
          )}
          <BooleanInput
            label="Send email notifications"
            source="isEmailNotifications"
          />
          <FunctionField
            addLabel={false}
            render={(record) => {
              return (
                <TextInput
                  source="notificationEmail"
                  validate={[required(), email()]}
                  defaultValue={record.email}
                />
              );
            }}
          ></FunctionField>
        </FormTab>
        <FormTab label="Addresses">
          <ArrayInput source="addresses" label="" style={{ width: "100%" }}>
            <SimpleFormIterator classes={classes}>
              <TextInput source="name" classes={classes} />
              <TextInput
                source="country"
                classes={classes}
                defaultValue="USA"
              />
              <TextInput source="state" classes={classes} />
              <TextInput source="city" classes={classes} />
              <TextInput source="street" classes={classes} />
              <TextInput source="zip" classes={classes} />
              <TextInput source="phone" classes={classes} />
            </SimpleFormIterator>
          </ArrayInput>
        </FormTab>
      </TabbedForm>
    </Edit>
  );
});
