export const desialMesialConfig = {
  16: {
    left: 'd',
    right: 'm',
    m: 'right',
    d: 'left',
    nr: 3,
  },
  15: {
    left: 'd',
    right: 'm',
    m: 'right',
    nr: 4,
    d: 'left',
  },
  14: {
    left: 'd',
    right: 'm',
    nr: 5,
    m: 'right',
    d: 'left',
  },
  13: {
    left: 'd',
    right: 'm',
    nr: 6,
    m: 'right',
    d: 'left',
  },
  12: {
    left: 'd',
    right: 'm',
    nr: 7,
    m: 'right',
    d: 'left',
  },
  11: {
    left: 'd',
    nr: 8,
    right: 'm',
    m: 'right',
    d: 'left',
  },
  21: {
    left: 'm',
    nr: 9,
    right: 'd',
    d: 'right',
    m: 'left',
  },
  22: {
    nr: 10,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  23: {
    nr: 11,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  24: {
    nr: 12,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  25: {
    nr: 13,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  26: {
    nr: 14,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  46: {
    nr: 30,
    left: 'd',
    right: 'm',
    m: 'right',
    d: 'left',
  },
  45: {
    left: 'd',
    nr: 29,
    right: 'm',
    m: 'right',
    d: 'left',
  },
  44: {
    nr: 28,
    left: 'd',
    right: 'm',
    m: 'right',
    d: 'left',
  },
  43: {
    nr: 27,
    left: 'd',
    right: 'm',
    m: 'right',
    d: 'left',
  },
  42: {
    nr: 26,
    left: 'd',
    right: 'm',
    m: 'right',
    d: 'left',
  },
  41: {
    nr: 25,
    left: 'd',
    right: 'm',
    m: 'right',
    d: 'left',
  },
  31: {
    nr: 24,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  32: {
    nr: 23,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  33: {
    nr: 22,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  34: {
    nr: 21,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  35: {
    nr: 20,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
  36: {
    nr: 19,
    left: 'm',
    right: 'd',
    d: 'right',
    m: 'left',
  },
}