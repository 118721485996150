import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import style from './style';

const Card = withStyles(style)(({ classes, card, isSelected, deleteCard, cardClick, ...props }) => {
  const { id, last4, exp_month, exp_year } = card;
  const parseCardNumber = last4 => ` xxxx xxxx xxxx ${last4}`;

  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <div className={isSelected ? classes.SelectedCard : classes.Card} onClick={() => cardClick()}>
        <span className={classes.CardNumber}>{parseCardNumber(last4)}</span>
        <div>
          <span className={classes.CardMonth}>{exp_month}</span>
          <span className={classes.CardYear}>{exp_year}</span>
        </div>
      </div>
      <Button style={{ minWidth: 0, padding: 0, margin: 0 }} onClick={() => deleteCard(id)}><DeleteOutlinedIcon color="primary" /></Button>
    </div>
  )
});

export default Card;