import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

export const unionThemeColorsSet = () => {
  const colorObj = {
    sts: {
      primaryColor: {
        light: '#00b3c5',
        main: '#00b3c5',
        dark: '#58595b',
        contrastText: '#e6e7e8',
      },
      secondaryColor: {
        light: '#0365b1',
        main: '#0365b1',
        dark: '#0365b1',
        contrastText: '#e6e7e8',
      }
    },

    ste: {
      primaryColor: {
        light: '#7c209a',
        main: '#7c209a',
        dark: '#7c209a',
        contrastText: '#fff',
      },
      secondaryColor: {
        light: '#c158dc',
        main: '#c158dc',
        dark: '#c158dc',
        contrastText: '#fff',
      }
    },

    reb: {
      primaryColor: {
        light: '#01b1ea',
        main: '#01b1ea',
        dark: '#01b1ea',
        contrastText: '#fff',
      },
      secondaryColor: {
        light: '#01b1ea',
        main: '#01b1ea',
        dark: '#01b1ea',
        contrastText: '#fff',
      }
    },

    health: {
      primaryColor: {
        light: '#002D5A',
        main: '#002D5A',
        dark: '#002D5A',
        contrastText: '#fff',
      },
      secondaryColor: {
        light: '#84C5E7',
        main: '#84C5E7',
        dark: '#84C5E7',
        contrastText: '#fff',
      }
    },
  }

  return colorObj[process.env.REACT_APP_THEME];
}

const getFontByEnvironment = () => {
  const fonts = {
    sts: 'AvenirNext',
  }
  if(fonts[process.env.REACT_APP_THEME]) {
    return fonts[process.env.REACT_APP_THEME];
  }

  return 'Exo';
}

export const fontStyle = getFontByEnvironment();

const { primaryColor, secondaryColor } = unionThemeColorsSet()

const customTheme = createMuiTheme({
  palette: {
    primary: primaryColor,
    secondary: secondaryColor,
    error: red,
    contrastThreshold: 3,
    tonalOffset: 0.2,
    background: {
      default: "#FFF"
    },
    text: {
      primary: primaryColor.light,
      secndary: primaryColor.light,
    },
    divider: secondaryColor.light,
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    useNextVariants: true,
    title: {
      fontWeight: 700,
      fontFamily: `'${fontStyle}', 'sans-serif'`,
    },
    body1: {
      fontFamily: "'Open Sans', 'sans-serif'"
    },
    fontFamily: [
      "Roboto",
      "Helvetica",
      "Arial",
      "sans-serif"
    ].join(','),
  },
  overrides: {
    MuiAppBar: {
      colorSecondary: {
        fontWeight: '700',
        color: "#FFF",
        backgroundColor: primaryColor.light,
      }
    },
    MuiTableCell: {
      head: {
        fontWeight: 700,
        fontFamily: `'${fontStyle}', 'sans-serif'`,
        color: primaryColor.light,
        fontSize: '15px'
      }
    },
    MuiMenuItem: {
      root: {
        fontWeight: 700,
        fontFamily: `'${fontStyle}', 'sans-serif'`,
      }
    },
    MuiButton: {
      root: {
        color: 'white',
      },
      disabled: {
        opacity: '0.5!important',
        color: '#fff!important'
      },
      textPrimary: {
        color: primaryColor.light,
        fontWeight: 700,
        fontFamily: `'${fontStyle}', 'sans-serif'`,
      },
      containedPrimary: {
        color: "#FFF",
        backgroundColor: primaryColor.light
      }
    },
  },
});


export default customTheme;