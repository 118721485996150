import back from "../../img/background.png";
import { fontStyle } from '../../theme';

const stylesHealth = {
  LogPage: {
    height: "100vh",
    display: "flex",
    alignItems: "stretch",
    "@media (max-width:780px)": {
      flexDirection: "column"
    }
  },
  LeftSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    "@media (max-width:780px)": {
      width: "100%",
      backgroundImage: `none`
    }
  },
  RightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "@media (max-width:780px)": {
      width: "100%"
    }
  },
  Logo: {
    maxWidth: "350px",
    "@media (max-width:780px)": {
      maxWidth: "100px",
      margin: "20px 0"
    }
  },
  Text: {
    fontSize: "26px",
    fontFamily: `'${fontStyle}', 'sans-serif'`,
    fontWeight: 700,
    "@media (max-width:780px)": {
      textAlign: "center"
    }
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "340px",
    marginTop: "25px"
  },
  TextInput: {
    margin: "15px 0",
    borderColor: "#68b8e2"
  },
  Button: {
    backgroundColor: "#68b8e2",
    marginTop: "25px",
    padding: "15px"
  }
};

const stylesSTS = {
  LogPage: {
    height: "100vh",
    display: "flex",
    alignItems: "stretch",
    "@media (max-width:780px)": {
      flexDirection: "column"
    }
  },
  LeftSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    "@media (max-width:780px)": {
      width: "100%",
      backgroundImage: `none`
    }
  },
  RightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "@media (max-width:780px)": {
      width: "100%"
    }
  },
  Logo: {
    maxWidth: "350px",
    "@media (max-width:780px)": {
      maxWidth: "100px",
      margin: "20px 0"
    }
  },
  Text: {
    fontSize: "26px",
    fontFamily: `'${fontStyle}', 'sans-serif'`,
    fontWeight: 700,
    "@media (max-width:780px)": {
      textAlign: "center"
    }
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "340px",
    marginTop: "25px"
  },
  TextInput: {
    margin: "15px 0",
    borderColor: "#60e0b3",

    "&:focus": {
      borderColor: "#60e0b3"
    }
  },
  Button: {
    backgroundColor: "#60e0b3",
    marginTop: "25px",
    padding: "15px",

    "&:hover": {
      backgroundColor: "#26c2cc"
    }
  }
};

const stylesSTE = {
  LogPage: {
    height: "100vh",
    display: "flex",
    alignItems: "stretch",
    "@media (max-width:780px)": {
      flexDirection: "column"
    }
  },
  LeftSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    "@media (max-width:780px)": {
      width: "100%",
      backgroundImage: `none`
    }
  },
  RightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "@media (max-width:780px)": {
      width: "100%"
    }
  },
  Logo: {
    maxWidth: "350px",
    "@media (max-width:780px)": {
      maxWidth: "100px",
      margin: "20px 0"
    }
  },
  Text: {
    fontSize: "26px",
    fontFamily: `'${fontStyle}', 'sans-serif'`,
    fontWeight: 700,
    color: "#7c209a",
    "@media (max-width:780px)": {
      textAlign: "center"
    }
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "340px",
    marginTop: "25px"
  },
  TextInput: {
    margin: "15px 0",
    borderColor: "#7c209a",

    "&:focus": {
      borderColor: "#7c209a"
    }
  },
  Button: {
    backgroundColor: "#8e44ad",
    marginTop: "25px",
    padding: "15px",

    "&:hover": {
      backgroundColor: "#9b59b6"
    }
  }
};

const stylesReb = {
  LogPage: {
    height: "100vh",
    display: "flex",
    alignItems: "stretch",
    "@media (max-width:780px)": {
      flexDirection: "column"
    }
  },
  LeftSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: `url(${back})`,
    backgroundSize: "cover",
    "@media (max-width:780px)": {
      width: "100%",
      backgroundImage: `none`
    }
  },
  RightSide: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#fff",
    "@media (max-width:780px)": {
      width: "100%"
    }
  },
  Logo: {
    maxWidth: "350px",
    width: "100%",
    "@media (max-width:780px)": {
      maxWidth: "100px",
      margin: "20px 0"
    }
  },
  Text: {
    fontSize: "26px",
    fontFamily: `'${fontStyle}', 'sans-serif'`,
    fontWeight: 700,
    color: "#251a06",
    "@media (max-width:780px)": {
      textAlign: "center"
    }
  },
  Form: {
    display: "flex",
    flexDirection: "column",
    maxWidth: "340px",
    marginTop: "25px"
  },
  TextInput: {
    margin: "15px 0",
    borderColor: "#251a06",

    "&:focus": {
      borderColor: "#251a06"
    }
  },
  Button: {
    backgroundColor: "#00b1ec",
    marginTop: "25px",
    padding: "15px",

    "&:hover": {
      backgroundColor: "#00b1ec"
    }
  }
};

const themeSwicher = {
  sts: stylesSTS,
  health: stylesHealth,
  ste: stylesSTE,
  reb: stylesReb
};

const styles = themeSwicher[process.env.REACT_APP_THEME];

export default styles;
