import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import styles from './styles';

const capitalize = string => {
  if (process.env.NODE_ENV !== 'production' && typeof string !== 'string') {
    throw new Error('Material-UI: capitalize(string) expects a string argument.');
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

const ControlWithLabel = (props) => {
  const {label, className, labelPlacement, classes, control} = props;
  const controlProps = {};
  
  ['checked', 'name', 'onChange', 'value', 'inputRef'].forEach(key => {
    if (typeof control.props[key] === 'undefined' && typeof props[key] !== 'undefined') {
      controlProps[key] = props[key];
    }
    });
  
  return (
    <label 
      className={classNames(
        classes.root,
        {
          [classes[`labelPlacement${capitalize(labelPlacement)}`]]: labelPlacement !== 'end',
        },
        className,
      )}
    >
      {React.cloneElement(control, controlProps)}
      <span className={classNames(classes.label)}>{label}</span>
    </label>
  )
}

export default withStyles(styles, { name: 'MuiFormControlLabel' })(ControlWithLabel);