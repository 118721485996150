import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import { withStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import { injectStripe } from "react-stripe-elements";
import CardForm from "./components/CardForm";
import TotalCount from "./components/TotalCount";
import CardsList from "./components/CardsList";
import config from "../../../config";

const styles = {
  ModalContent: {
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
  },
  Label: {
    userSelect: "none",
  },
};

const Title = ({ title }) => (
  <FormLabel
    style={{
      color: "rgb(193, 88, 220)",
      fontSize: "25px",
    }}
    component="legend"
  >
    {title}
  </FormLabel>
);

const Modal = withStyles(styles)(
  ({
    open,
    onClose,
    classes,
    cards,
    shipments,
    getCards,
    caseId,
    payShipment,
    record,
    setPendingPayment,
    ...props
  }) => {
    const [view, setView] = useState(0);
    const [newCard, toggleNewCard] = useState(0);
    const [isUnlimit, setUnlimitPlan] = useState(false);
    const [links, setLinks] = useState([]);
    const [tp, setTp] = useState(links[0]);
    const checkFor3DRender = (message) => {
      if (message.indexOf("https://dentadynamics.com") !== -1) {
        const links = message.match(/https:\/\/dentadynamics\.com[^ ]*\b/g);

        return links[0];
      }

      return null;
    };

    useEffect(() => {
      const found = record.chat
        .map((item) => checkFor3DRender(item.message))
        .filter((item) => item !== null);

      setLinks(found);

      if (found.length) {
        setTp(found[found.length - 1]);
      }
    }, []);

    useEffect(() => {
      if (
        getIsUnlimited() ||
        shipments[shipments.length - 1].isUnlimit === true
      ) {
        setUnlimitPlan(true);
      }
    }, [shipments]);

    useEffect(() => {
      !!open && setView(0);
    }, [open]);

    const cancelClick = () => {
      setView(0);
      onClose();
    };

    const getIsUnlimited = () => {
      return (
        shipments[shipments.length - 1].price >=
        config.unlimitedPrice[process.env.REACT_APP_THEME]
      );
    };

    const shipmentToPay = !!shipments.length
      ? shipments[shipments.length - 1]
      : { quantity: 0, price: 0 };

    const viewContent = {
      0: () => (
        <TotalCount
          shipment={shipmentToPay}
          setView={setView}
          onCancel={cancelClick}
          unlimited={isUnlimit}
          setPendingPayment={() => setPendingPayment(tp, shipments)}
        />
      ),
      1: () =>
        cards.length && !newCard ? (
          <CardsList
            cards={cards}
            shipment={shipmentToPay}
            onCancel={cancelClick}
            toggleNewCard={toggleNewCard}
            getCards={getCards}
            record={record}
            payShipment={payShipment}
            tp={tp}
            isUnlimit={isUnlimit}
          />
        ) : (
          <CardForm
            stripe={props.stripe}
            shipment={shipmentToPay}
            onCancel={cancelClick}
            toggleNewCard={toggleNewCard}
            cards={cards}
            getCards={getCards}
            record={record}
            tp={tp}
            payShipment={payShipment}
          />
        ),
    };

    return (
      <Dialog
        open={open}
        onClose={cancelClick}
        className={classes.Modal}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Checkout</DialogTitle>
        <DialogContent>
          <div className={classes.ModalContent}>
            {viewContent[view]()}
            {view !== 1 && (
              <>
                {!links.length ? (
                  <Title title="No TP Attachments"></Title>
                ) : (
                  <Select
                    value={tp}
                    onChange={(e) => setTp(e.target.value)}
                    style={{
                      color: "#002D5A",
                      margin: "50px 0 25px",
                      fontSize: "25px",
                    }}
                  >
                    {links.map((link, idx) => (
                      <MenuItem value={link}>TP {idx + 1}</MenuItem>
                    ))}
                  </Select>
                )}
                <FormControlLabel
                  style={styles.Label}
                  control={
                    <Checkbox
                      onChange={(e) => setUnlimitPlan(!isUnlimit)}
                      value="checkedUnlimitPlan"
                      color="primary"
                      checked={isUnlimit}
                      disabled={isUnlimit && getIsUnlimited()}
                    />
                  }
                  label="Unlimited Plan"
                />
              </>
            )}
          </div>
        </DialogContent>
      </Dialog>
    );
  }
);

export default injectStripe(Modal);
