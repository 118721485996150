import { showNotification } from 'react-admin';

export const formatDate = val => {
  if (!val) {
    return;
  }

  let date = new Date(val);

  if (date.getFullYear() === 1970) {
    date = new Date(date.getTime() * 1000);
  }

  return date.toLocaleString('en-US');
}

export const formatDateInHours = val => {
  if (!val) {
    return;
  }
  const date = new Date(val);
  return date.toLocaleString('en-US');
}


export const getIfExists = (source, record) => {
  const pathWithData = (source.indexOf('data.') !== -1) ?
    source.split('.') :
    ['data', ...source.split('.')];

  let currentLevel = record;
  if (!record) {
    return false;
  };

  const fieldExsits = pathWithData.every(level => {
    if (currentLevel[level]) {
      currentLevel = currentLevel[level];
      return true;
    }
    return false;
  });

  return fieldExsits ? currentLevel : false;
}

export const fetchEntityChanges = ({ url, method, newData, handleResponse }) => {
  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  const authToken = localStorage.getItem('authToken');

  if (authToken) {
    headers.set('Authorization', `Bearer ${authToken}`);
  }

  fetch(url, {
    method: method || 'PATCH',
    body: newData ? JSON.stringify(newData) : null,
    headers
  })
    .then(response => response.json())
    .then(response => handleResponse(response))
    .catch((e) => {
      console.error(e);
      showNotification('Error: unable to perform transition', 'warning')
    });
}

export const makeId = (length, prefix) => {
  let result = '';

  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  if (prefix) {
    return `${prefix}${result}`;
  }

  const appTheme = process.env.REACT_APP_THEME;

  if (appTheme === 'sts') {
    result = `A${result}`;
  } else if (['ste', 'reb'].indexOf(appTheme) !== -1) {
    result = `B${result}`;
  } else if (appTheme === 'health') {
    result = `C${result}`;
  }


  return result;
}
