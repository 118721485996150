import { fontStyle } from '../../../theme';

const styles = {
  SubTitle: {
    fontSize: '16px',
    color: '#84C5E7',
    lineHeight: 2,
    marginRight: "20px"
  },
  Button: {
    backgroundColor: '#84C5E7',
    fontFamily: `'${fontStyle}', 'sans-serif'`,
    fontWeight: 700,
    margin: '0 10px',
    color: "#FFF"
  },
  BottomPanel: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "20px -10px -10px",
    order: 1,
  },
  BottomPanelCentered: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px -10px -10px"
  }
};

export default styles;