import React from 'react';
import * as config from '../constants';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import defaultCase from '../../../utils/defaultCase';
import { getIfExists } from '../../../utils';

const ObjectField = withStyles(styles)(({
  record = defaultCase,
  classes,
  source
}) => {

  const path = source.split('.');
  const nestedField = ['midline', 'relationship'].indexOf(path[0]) !== -1;
  const divideWords = text => (
    text.split('').map(letter => (
      letter === letter.toUpperCase() ? ` ${letter}` : letter)
    )
      .join('')
  );

  const constsToArray = (accumulator, key) => {
    if (Array.isArray(config[path[0]][key])) {
      return [...accumulator, ...config[path[0]][key]]
    }

    return constsToArray(accumulator, config[path[0]][key]);
  }

  const texts = Object.keys(config[path[0]]).reduce(constsToArray, []);
  const getText = (id) => texts.find(itm => itm.id === id).name;

  const dateObject = getIfExists(source, record);
  const haveValues = dateObject && Object.keys(dateObject).length;

  const RenderedComponent = ({ prop }) => nestedField ?
    (<div key={prop} className={classes.wrapper}>
      <div className={classes.nestedLabel}> {divideWords(prop)}</div>
      <span className={classes.nestedValue} >
        {(texts[dateObject.prop]) ? getText(dateObject[prop]) : dateObject[prop]} </span>
    </div>) :
    (<div key={prop} className={classes.wrapper}>
      <div className={classes.label}> {getText(prop)}</div>
      <span className={classes.value}> {record['data'][source][prop]} </span>
    </div>)

  return haveValues ?
    Object.keys(dateObject).map(prop =>
      <RenderedComponent key={prop} prop={prop} />) :
    <div className={classes.empty}>empty</div>
});

export default ObjectField;