import React from 'react';
import {
  CardActions,
  Filter,
  SelectInput,
  ExportButton,
  CreateButton,
  List,
  Datagrid,
  FunctionField,
  DateField,
  Pagination,
  TextInput,
  ReferenceField,
} from 'react-admin';
import { DateInput } from 'react-admin-date-inputs';
import {
  getPermissions,
  hasPermission,
} from '../../../utils/permissions.utils';
import { caseStatusFilter } from '../constants';
import InputRow from '../InputRow/';

const CasePagination = (props) => (
  <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
);

const Filters = ({ permissions, ...props }) => {
  return (
    <Filter {...props}>
      <TextInput label='Search' source='caseId' alwaysOn />
      {permissions && (
        <SelectInput
          source='status'
          allowEmpty={false}
          alwaysOn
          choices={caseStatusFilter}
          filterDefaultValues={caseStatusFilter[0]}
        />
      )}
      <InputRow source='ByDate'>
        <DateInput
          source='ByDate.from'
          label='From'
          options={{ format: 'dd/MM/YYYY' }}
        />
        <DateInput
          source='ByDate.to'
          label='To'
          options={{ format: 'dd/MM/YYYY' }}
        />
      </InputRow>
    </Filter>
  );
};

const ListActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total,
  permissions,
}) => (
  <CardActions>
    {bulkActions &&
      React.cloneElement(bulkActions, {
        basePath,
        filterValues,
        resource,
        selectedIds,
        onUnselectItems,
      })}
    {filters &&
      React.cloneElement(filters, {
        resource,
        showFilter,
        displayedFilters,
        filterValues,
        context: 'button',
      })}
    {!hasPermission(permissions, 'CASE_MANAGER') && (
      <CreateButton basePath={basePath} label='CREATE NEW CASE' />
    )}

    <ExportButton
      disabled={total === 0}
      resource={resource}
      sort={currentSort}
      filter={filterValues}
      exporter={exporter}
    />
  </CardActions>
);

export const CaseList = ({ permissions, ...props }) => {
  const translations = JSON.parse(localStorage.getItem('translations'));
  const roles = localStorage.getItem('roles');

  return (
    <List
      {...props}
      actions={<ListActions permissions={permissions} />}
      filterDefaultValues={{ status: getPermissions(roles) }}
      filters={permissions && <Filters permissions={permissions} />}
      sort={{ field: 'updated', order: 'DESC' }}
      pagination={<CasePagination />}
      perPage={100}
    >
      <Datagrid rowClick='edit'>
        <FunctionField
          label='Case ID number'
          render={(record) => (record.caseId ? record.caseId : record.id)}
        />
        <FunctionField
          label='Patient Name'
          render={(record) =>
            record.patientName ? record.patientName : 'None provided'
          }
        />
        <FunctionField
          sortBy='status'
          label='Status'
          render={(record) =>
            record && record.status && !!translations
              ? translations[record.status]
              : ''
          }
        />
        <ReferenceField
          label='Provider'
          source='submiterId'
          reference='users'
          linkType={false}
        >
          <FunctionField
            render={(record) => `${record.firstname} ${record.lastname}`}
          />
        </ReferenceField>
        <DateField label='Case updated' source='updated' showTime />
        <DateField label='Case submitted' source='created' showTime />
      </Datagrid>
    </List>
  );
};
