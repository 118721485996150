import React, { useState, useEffect } from 'react';
import { desialMesialConfig } from './config';
import {
  unionThemeColorsSet,
} from '../../../theme';
import arrowRight from './images/arrow-left.svg';
import arrowLeft from './images/arrow-right.svg';

const getSide = (number, dm) => {
  return desialMesialConfig[number][dm];
}

const SIDES = {
  right: 'right',
  left: 'left',
};

const getMiddleValue = (tooth, next) => {
  if (!next) {
    return null;
  }

  const sides = {
    d: 'left',
    m: 'right',
  };
  const dmCurrent = sides[desialMesialConfig[tooth.number][SIDES.right]];
  const dmNext = sides[desialMesialConfig[next.number][SIDES.left]];

  const currentTooth = {
    mm: tooth[`${dmCurrent}SideMm`] || 0,
    step: tooth[`${dmCurrent}SideStep`],
  };

  const nextTooth = {
    mm: next[`${dmNext}SideMm`] || 0,
    step: next[`${dmNext}SideStep`],
  };

  if (currentTooth.mm || nextTooth.mm) {
    const sum = parseFloat(currentTooth.mm) + parseFloat(nextTooth.mm);

    return {
      mm: sum.toFixed(1) || 0,
      step: currentTooth.step || nextTooth.step,
    };
  }

  return null;
}

const getArrow = (number, dm) => {
  return getSide(number, dm) === 'left' ? arrowLeft : arrowRight
}


const { primaryColor, secondaryColor } = unionThemeColorsSet();


export default function Tooth({ img, data, number, next }) {
  const [isActive, setIsActive] = useState(false);
  const [sum, setSum] = useState(null);

  useEffect(() => {
    if (data) {
      const {
        isAttachment
      } = data;

      if (isAttachment) {
        setIsActive(true);
      }

      setSum(getMiddleValue(data, next));
    }
  }, []);


  return (
    <div className="teeth">
      <div className="tooth">
        {
          data && (data.leftSideStep || data.leftSideMm)
            ? <img className={`tooth__arrow-${getSide(number, 'd')}`} src={getArrow(number, 'd')} alt="arrow left" />
            : null
        }

        {
          data && (data.rightSideStep || data.rightSideMm)
            ? <img className={`tooth__arrow-${getSide(number, 'm')}`} src={getArrow(number, 'm')} alt="arrow left" />
            : null
        }
        <img className={`tooth__icon ${isActive ? 'active' : ''}`} src={img} alt="tooth icon" />
        <div className="tooth__number">{desialMesialConfig[number].nr}</div>
        {sum ? (
          <div className="middle-info" style={{ color: primaryColor.dark }}>
            <div className="mm">
              {sum.mm}
            </div>
            <div className="step">
              Before step: {sum.step}
            </div>
          </div>
        ) : null}
        <div className={`tooth__${getSide(number, 'd')}-side`}>
          {
            data && data.leftSideStep
              ? <div className={`tooth__${getSide(number, 'd')}-side__step`} style={{ color: primaryColor.dark }}>Before step: {data.leftSideStep}</div>
              : null
          }

          {
            data && data.leftSideMm
              ? <div className={`tooth__${getSide(number, 'd')}-side__mm`} style={{ color: primaryColor.dark }}>{data.leftSideMm}</div>
              : null
          }

        </div>
        <div className={`tooth__${getSide(number, 'm')}-side`}>
          {
            data && data.rightSideStep
              ? <div className={`tooth__${getSide(number, 'm')}-side__step`} style={{ color: primaryColor.dark }}>Before step: {data.rightSideStep}</div>
              : null
          }

          {
            data && data.rightSideMm
              ? <div className={`tooth__${getSide(number, 'm')}-side__mm`} style={{ color: primaryColor.dark }}>{data.rightSideMm}</div>
              : null
          }

        </div>
      </div>
    </div>
  )
}
