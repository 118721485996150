import React from 'react';
import { SelectInput } from 'react-admin';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import * as constants from '../constants';

const styles = {
  root: {
    display: 'inline-flex',
    flexDirection: 'column',
    width: '50%',
    minWidth: '300px'
  }
}

const Relationship = withStyles(styles)(({ source, label, classes, disabled }) => (
  <div className={classes.root}>
    <FormLabel component="legend">{label}</FormLabel>
    <SelectInput
      className={classes.root}
      source={`${source}.rightClass`}
      label="Right class"
      disabled={disabled}
      choices={constants.relationship.classes}
    />
    <SelectInput
      className={classes.root}
      source={`${source}.leftClass`}
      label="Left class"
      disabled={disabled}
      choices={constants.relationship.classes}
    />
  </div>
));

export default Relationship;