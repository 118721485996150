import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { formatDate } from '../../../utils';

const styles = theme => ({
  root: {
    width: 'auto',
    marginTop: theme.spacing.unit * 3,
    overflowX: 'auto',
  },
  table: {
    position: 'relative',
    maxWidth: '700px',
  }
});

const dateCols = ['created', 'payedAt'];
const renameCols = {
  cardLast4: "Card No"
}

const getAllCols = (data) =>
  data.reduce((colsArr, row) => [...colsArr, ...Object.keys(row).filter((itm => colsArr.indexOf(itm) === -1))], []);

const formatHeaderText = text => {
  const currentText = renameCols[text] ? renameCols[text] : text;
  let letterArr = currentText.split('');
  letterArr = letterArr.map(letter => (letter === letter.toUpperCase()) ? ` ${letter}` : letter);
  letterArr[0] = letterArr[0].toUpperCase();
  return letterArr.join('');
}

const formatBodyText = (col) => {
  if (dateCols.indexOf(col[0]) !== -1) {
    return formatDate(col[1]);
  }
  if (col[0] === 'cardLast4') {
    return `xxxx xxxx xxxx ${col[1]}`
  }

  return col[1]

}

const SimpleTable = ({ classes, data }) => {
  return (
    <Table className={classes.table}>
      <TableHead className={classes.tableHead}>
        <TableRow>
          {getAllCols(data).map(col => col !== 'chargeId' ?
            (<TableCell
              component="th"
              key={col} scope="row"
            >
              {formatHeaderText(col)}
            </TableCell>) : null
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, i) => (
          <TableRow key={i}>
            {Object.entries(row).map(
              (col, index) => {
                let val = formatBodyText(col);

                return col[0] !== 'chargeId' ? (
                  <TableCell
                    style={{ minWidth: "130px" }}
                    component="th"
                    key={`col${index}`}
                    scope="row">
                    {val}
                  </TableCell>
                ) : null
              }
            )}
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

export default withStyles(styles)(SimpleTable);