import React, { useState, cloneElement, Children, } from "react";
import { MenuItemLink } from 'react-admin';
import Tooltip from '@material-ui/core/Tooltip';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/core/styles';
import style from "./style";
// import { connect } from "react-redux";

const CustomUserMenu = withStyles(style)(
  props => {
    const { children, label, logout, translate, classes, onMenuClick } = props;

    if (!logout && !children) return null;

    const [anchorEl, setAnchorEl] = useState(null);

    const open = Boolean(anchorEl);

    const handleMenu = event => setAnchorEl(event.currentTarget);

    const handleClose = () => setAnchorEl(null);

    return (
      <>
        <Tooltip title="Profile">
          <Button
            className={classes.BtnUserName}
            arial-label={label && translate(label, { _: label })}
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={handleMenu}
            color="inherit"
          >
            <span className={classes.UserName}>{localStorage.getItem("user")}</span>
            <AccountCircle />
          </Button>
        </Tooltip>
        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={open}
          onClose={handleClose}
        >
          {Children.map(children, menuItem =>
            cloneElement(menuItem, { onClick: handleClose })
          )}
          {logout}
          <MenuItemLink
            to={`/users/${localStorage.getItem('usrId')}`}
            primaryText="Profile"
            leftIcon={<AccountCircle />}
            onClick={onMenuClick}
          />
        </Menu>
      </>
    );
  }
)

export default CustomUserMenu;