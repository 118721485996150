const secoundaryColor = "#26c2cc";
const primaryColor = "#60e0b3";

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    background: 'red',
    width: '100%'
  },
  card: {
    root: {
      width: '100%'
    }
  },
  complaint: {
    maxWidth: '700px',
    display: 'block'
  },
  caseId: {
    width: '300px'
  },
  twoBlocks: {
    maxWidth: '800px',
    width: '100% !important',
    marginTop: '25px',
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  block: {
    width: '50%'
  },
  title: {
    fontSize: '25px',
    margin: '50px 0 25px',
    color: '#002D5A'
  },
  hidden: {
    display: 'none'
  },
  subTitle: {
    fontSize: '16px',
    margin: '0 0 15px',
    color: '#555'
  },
  subValue: {
    color: '#002D5A',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: "'Open Sans', 'sans-serif'",
    lineHeight: 1
  },
  subLabel: {
    display: 'inline-block',
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    marginBottom: '5px',
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    lineHeight: 1,
    marginRight: '10px',
    textTransform: 'capitalize'
  },
  SimpleFormIterator: {
    root: {
      backgroundColor: 'green',
      paddingLeft: '10px'
    }
  },
  newLine: {
    display: 'block',
    paddingTop: '15px',
    width: '700px'
  },
  shipment: {
    root: {
      backgroundColor: 'red',
      paddingLeft: '10px'
    }
  },
  TextInput: {
    marginLeft: '10px'
  },
  EmptyText: {
    fontSize: '20px',
    color: '#DDD',
    padding: '20px',
    display: 'block'
  },
};

export default styles;