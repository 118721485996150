import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { withStyles } from '@material-ui/core/styles';
import QtyPrice from './QtyPrice';
import TrackNum from './TrackNum';
import Replacement from './Replacements';
import ReceivePayment from './ReceivePayment';
import config from '../../../config';

const styles = {
  InputRow: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  halfWidth: {
    width: '45%',
  },
};

const Modal = withStyles(styles)(
  ({
    open,
    onClose,
    handleTrackNum,
    status,
    isTrackNumber,
    isNumQty,
    isPrice,
    isAmount,
    isReceivePayment,
    classes,
    shipments,
    replacement,
    confirmChangeStatus,
    receivePaymentHandler,
    ...props
  }) => {
    const [trackNum, setTrackNum] = useState('');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(0);
    const [shipmentType, setShipmentType] = useState('ALIGNER');
    const [comment, setComment] = useState('');
    const [unlimitedPlan, setUnlimitedPlan] = useState(false);
    const [receivePaymentNote, setReceivePaymentNote] = useState('');
    const [receivePaymentStatus, setReceivePaymentStatus] = useState(false);

    useEffect(() => {
      fetch(`${config.api}/payment/amount?brackets=${quantity}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('authToken')}`,
        },
      })
        .then((res) => res.json())
        .then((res) => setPrice(res));
    }, [quantity]);

    const changeQuantity = (qty) => {
      setQuantity(qty);
    };

    useEffect(() => {
      if (price >= config.unlimitedPrice[process.env.REACT_APP_THEME]) {
        setUnlimitedPlan(true);
        setPrice(
          process.env.REACT_APP_THEME !== 'ste'
            ? price
            : config.unlimitedPrice[process.env.REACT_APP_THEME],
        );
      } else {
        setUnlimitedPlan(false);
      }
    }, [price]);

    const getDialogTitle = () => {
      console.log(status);
      if (status === 'PRODUCE_REPLACEMENTS') {
        return 'Make a replacement';
      }

      if (status === 'REPLACEMENT_REQUIRED') {
        return 'Request Replacement';
      }

      if (['NOT_SUITABLE', 'ARCHIVED'].indexOf(status) !== -1) {
        return 'Change status';
      }

      if (status === 'ALIGNER_SHIPPED') {
        return 'Tracking Number';
      }

      if (status === 'RETAINER_SHIPPED') {
        return 'Retainer shipped';
      }

      if (status === 'RECEIVE_PAYMENT')
        return 'Receive Payment'

      return 'Subscribe';
    };

    const isStatusConfirm = () =>
      !isPrice && !isAmount && !isTrackNumber && !replacement && !isReceivePayment;

    const SubmitButton = () => {
      if (isStatusConfirm()) {
        return (
          <Button onClick={() => confirmChangeStatus(status)} color='primary'>
            Change
          </Button>
        );
      }
      if(isReceivePayment) {
        return (
          <Button color="primary" onClick={() => receivePaymentHandler(receivePaymentNote, receivePaymentStatus)}>
            Receive
          </Button>
        )
      }

      return (
        <Button
          onClick={(e) =>
            handleTrackNum(
              { trackNum, quantity, price, unlimitedPlan },
              status,
              shipmentType,
              comment,
            )
          }
          color='primary'
          disabled={quantity < 1}
        >
          Post
        </Button>
      );
    };

    return (
      <Dialog open={open} onClose={onClose} aria-labelledby='form-dialog-title'>
        <DialogTitle id='form-dialog-title'>{getDialogTitle()}</DialogTitle>
        <DialogContent>
          {(!!isPrice || !!isAmount) && (
            <QtyPrice
              price={price}
              isAmount={isAmount}
              isPrice={isPrice}
              quantity={quantity}
              setPrice={setPrice}
              changeQuantity={changeQuantity}
              classes={classes}
              setUnlimitedPlan={setUnlimitedPlan}
              unlimitedPlan={unlimitedPlan}
            />
          )}
          {isTrackNumber && (
            <TrackNum trackNum={trackNum} setTrackNum={setTrackNum} />
          )}
          {replacement && (
            <Replacement
              shipmentType={shipmentType}
              setShipmentType={setShipmentType}
              comment={comment}
              setComment={setComment}
            />
          )}
          {isReceivePayment && (
            <ReceivePayment 
              setReceivePaymentNote={(e) => setReceivePaymentNote(e.target.value)}
              setReceivePaymentStatus={(e) => setReceivePaymentStatus(e.target.checked)}
            />
          )}
          {isStatusConfirm() && 'Are you sure?'}
        </DialogContent>
        <DialogActions>
          <SubmitButton />
        </DialogActions>
      </Dialog>
    );
  },
);

export default Modal;
