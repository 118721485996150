import React from 'react';
import axios from 'axios';
import Input from "./Input";
import MessageList from "./MessageList";
import config from "../../../config";
import { fetchEntityChanges } from "../../../utils";

function ChatApp({
  changeView,
  caseId,
  role,
  message,
  chats,
  setMessage,
  colorChange,
}) {

  const roles = {
    PROVIDER: 0,
    CASE_MANAGER: 1,
    ADMIN: 2,
  };
  const closeHandler = (e) => {
    e.preventDefault();
    changeView('messageMe');
  };

  const convertLinks = (msg) => {
    const exp = /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/ig;

    return msg.replace(exp, "<a href='$1' target='_blank'>$1</a>");
  };

  const handleSubmit = (evt) => {
    evt.preventDefault();

    const newData = {
      message: convertLinks(message),
      role: roles[role],
      caseId: caseId,
      created: new Date(),
    };
    const url = `${config.api}/cases/${caseId}`;
    fetchEntityChanges({ url, newData });

    if (message) {
      axios.post(`${process.env.REACT_APP_API}/cases/message`,
        newData, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('authToken')}`
        },
      });
    }
  };

  return (
    <div>
      <div className='chat-app'>
        <button onClick={closeHandler} className="chat_close">&times;</button>
        <h2 className="header" style={{
          backgroundColor: colorChange(process.env.REACT_APP_THEME),
          borderColor: colorChange(process.env.REACT_APP_THEME)
        }}></h2>
        <MessageList
          messages={chats}
          colorChange={colorChange}
        />
        <Input
          colorChange={colorChange}
          className="input-field"
          value={message}
          setMessage={setMessage}
          onSubmit={e => handleSubmit(e)} />
      </div>
    </div>
  )
}

export default ChatApp;
