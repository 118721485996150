import React, { useEffect, useState } from 'react';
import { withRouter } from "react-router";
import { push } from 'react-router-redux';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import { showNotification } from 'react-admin';
import Pusher from "pusher-js";
import ChatMessage from './ChatMessage';
import ChatApp from './ChatApp';
import MessageMe from "./MessageMe";
import './index.scss'


function ChatWidget({ record, permissions }) {
  const [currentView, setCurrentView] = useState('chatApp');
  const [chatHistory, setChatHistory] = useState(record.chat);
  const [caseId, setCaseId] = useState(record.id);
  const [chats, setChats] = useState([]);
  const [message, setMessage] = useState('');

  const actionsBtnBackgroundColor = (env) => {
    const envHandler = {
      'default': '#84C5E7',
      'sts': '#00b3c5',
      'ste': '#c158dc'
    };
    return envHandler[env] ? envHandler[env] : envHandler['default'];
  };

  useEffect(() => {
    const pusher = new Pusher(process.env.REACT_APP_PUSHER_APP_ID, {
      cluster: 'eu',
      forceTLS: true
    });

    const channel = pusher.subscribe(caseId);
    channel.bind('pusher:subscription_succeeded', () => {
      setChats(chatHistory);
    });
    channel.bind('my-event', data => {
      setChats(chats => [...chats, data]);
    });

    return () => {
      channel.unbind();
    }
  }, []);

  function changeView(view) {
    setCurrentView(view)
  }
  let view = '';
  if (currentView === "ChatMessage") {
    view = <ChatMessage changeView={changeView} />
  } else if (currentView === "chatApp") {
    view = <ChatApp
      changeView={changeView}
      chatHistory={chatHistory}
      caseId={caseId}
      role={permissions}
      message={message}
      setMessage={setMessage}
      chats={chats}
      colorChange={actionsBtnBackgroundColor}
    />
  }
  else if (currentView === 'messageMe') {
    view = <MessageMe
      colorChange={actionsBtnBackgroundColor}
      changeView={changeView}

    />
  }

  return (
    <div className={currentView === 'messageMe' ? 'ChatWidget off' : 'ChatWidget'}>
      {view}
    </div>
  );
}
export default withRouter(connect(null, {
  showNotification,
  push,
  change
})(({ source, record, permissions }) =>
  <Field
    name={source}
    component={ChatWidget}
    record={record}
    permissions={permissions}
  />));