const styles = {
  wrapper: {
    marginTop: '16px',
    marginBottom: '8px',
    display: 'flex',
    maxWidth: '800px',
    alignSelf: 'center'
  },
  nestedLabel: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    lineHeight: 1,
    marginRight: '10px',
    textTransform: 'capitalize'
  },
  label: {
    color: 'rgba(0, 0, 0, 0.54)',
    padding: 0,
    fontFamily: 'Roboto,Helvetica,Arial,sans-serif',
    lineHeight: 1,
    marginRight: '10px',
    width: '50%',
    textTransform: 'capitalize'
  },
  empty: {
    color: '#002D5A',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: "'Open Sans', 'sans-serif'",
    lineHeight: 1,
    marginTop: '-11px'
  },
  nestedValue: {
    color: '#002D5A',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: "'Open Sans', 'sans-serif'",
    lineHeight: '1',
    textTransform: 'uppercase',
  },
  value: {
    color: '#002D5A',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: "'Open Sans', 'sans-serif'",
    lineHeight: '1',
    textTransform: 'uppercase',
    width: '50%'
  },
}
export default styles;