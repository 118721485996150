export const caseStatuses = [
  { id: "NEW", name: "NEW" },
  { id: "CREATE_PLAN", name: "Create plan" },
  { id: "PLAN_AVAILABLE", name: "Plan available" },
  { id: "REFINE_SETUP", name: "Refine setup" },
  { id: "PRODUCE_ALIGNER", name: "Produce aligner" },
  { id: "IMPROVE_SETUP", name: "Improve setup" },
  { id: "ALIGNER_SHIPPED", name: "Aligner shipped" },
  { id: "ARCHIVED", name: "Archive" },
  { id: "PRODUCE_RETAINER", name: "Produce retainer" },
  { id: "RETAINER_SHIPPED", name: "Retainer shipped" },
  { id: "PLAN_APPROVED", name: "Plan Approved" },
  { id: "NOT_SUITABLE", name: "Not Suitable" },
  { id: "REPLACEMENT_REQUIRED", name: "Request replacement" },
  { id: "REPLACEMENT_PLAN_AVAILABLE", name: "Replacement plan available" },
  { id: "PRODUCE_REPLACEMENTS", name: "Produce replacements" },
  { id: "REPLACEMENTS_SHIPPED", name: "Replacements shipped" },
];

export const treat = [
  { id: "UPPER", name: "Upper" },
  { id: "LOWER", name: "Lower" },
  { id: "BOTH", name: "Both" },
];

export const midline = {
  directions: [
    { id: "CENTER", name: "CENTER" },
    { id: "LEFT", name: "LEFT" },
    { id: "RIGHT", name: "RIGHT" },
  ],
};

export const relationship = {
  classes: [
    { id: "1", name: "I" },
    { id: "2", name: "II" },
    { id: "3", name: "III" },
  ],
};

export const instructions = {
  types: [
    { id: "UPPER_MIDLINE", name: "Upper Midline" },
    { id: "LOWER_MIDLINE", name: "Lower Midline" },
    { id: "OVERJET", name: "Overjet" },
    { id: "OVERBITE", name: "Overbite" },
    { id: "ARCH_FORM", name: "Arch Form" },
    { id: "CANINE_RELATIONSHIP", name: "Canine Relationship" },
    { id: "MOLAR_RELATIONSHIP", name: "Molar Relationship" },
    { id: "POSTERIOR_CROSSBITE", name: "Posterior Crossbite" },
  ],
  aditionalTypes: [{ id: "DIASTEMAS", name: "Diastemas" }],
  procedures: [
    { id: "IPR", name: "Ipr" },
    { id: "ENGAGERS", name: "Engagers" },
    { id: "PROCLINE", name: "Procline" },
    { id: "EXPAND", name: "Expand" },
    { id: "DISTALIZE", name: "Distalize" },
  ],
  actions: [
    { id: "MAINTAIN", name: "MAINTAIN" },
    { id: "IMPROVE", name: "IMPROVE" },
    { id: "IDEALIZE", name: "IDEALIZE" },
  ],
  aditionalActions: [
    { id: "MAINTAIN", name: "MAINTAIN" },
    { id: "CLOSED", name: "CLOSED" },
    { id: "REDUCED", name: "REDUCED" },
  ],
  doProcedure: [
    { id: "YES", name: "YES" },
    { id: "NO", name: "NO" },
    { id: "IF NEEDED", name: "IF NEEDED" },
  ],
};

export const shipmentTypes = [
  { id: "ALIGNER", name: "ALIGNER" },
  { id: "RETAINER", name: "RETAINER" },
];

export const teethBlocks =
  ["sts", "ste", "reb"].indexOf(process.env.REACT_APP_THEME) !== -1
    ? [
        [1, 2, 3, 4, 5, 6, 7, 8],
        [9, 10, 11, 12, 13, 14, 15, 16],
        [32, 31, 30, 29, 28, 27, 26, 25],
        [24, 23, 22, 21, 20, 19, 18, 17],
      ]
    : [
        [18, 17, 16, 15, 14, 13, 12, 11],
        [21, 22, 23, 24, 25, 26, 27, 28],
        [48, 47, 46, 45, 44, 43, 42, 41],
        [31, 32, 33, 34, 35, 36, 37, 38],
      ];

export const caseStatusFilter = [
  { id: "ALL", name: "All" },
  { id: "PROVIDER", name: "Provider" },
  { id: "CASE_MANAGER", name: "Case Manager" },
  { id: "ARCHIVED", name: "Archived" },
];

export const imgExtensions = ["gif", "png", "jpg", "jpeg", "svg", "bmp"];
