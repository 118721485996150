import React, { useState } from "react";
import Card from "./components/Card";
import Button from '@material-ui/core/Button';
import styles from "../../style";
import { fetchEntityChanges } from "../../../../../utils";
import config from '../../../../../config';

const CardsList = ({
  cards,
  onCancel,
  shipment,
  toggleNewCard,
  getCards,
  payShipment,
  isUnlimit,
  record,
  tp,
}) => {

  const [selectedCard, toggleSelectedCard] = useState(null);

  const userId = localStorage.getItem('usrId');

  const deleteCard = cardId => {
    const url = `${config.api}/customer/${record.id}/cards`;
    fetchEntityChanges({ url, method: "DELETE", newData: { cardToken: cardId }, handleResponse: () => { getCards() } });
  }

  const priceToPay = isUnlimit ? config.unlimitedPrice[process.env.REACT_APP_THEME] : shipment.price;

  return (
    <div>
      <span style={styles.SubTitle}>Select Card:</span>
      {cards.length
        ? cards.map((card, i) =>
          <Card
            key={i}
            card={card}
            cardClick={() => toggleSelectedCard(card.id)}
            deleteCard={deleteCard}
            isSelected={selectedCard === card.id}
          />)
        : "No cards"
      }
      <div style={styles.BottomPanelCentered}>
        <Button
          style={styles.Button}
          disabled={selectedCard === null}
          onClick={() => payShipment(priceToPay, null, selectedCard, tp)}
        >
          {`Pay ${priceToPay}$`}
        </Button>
        <Button
          style={styles.Button}
          onClick={() => toggleNewCard(1)}
        >
          {!!cards.length ? "Add new card" : "Add card"}
        </Button>
        <Button style={styles.Button} onClick={() => onCancel()}>cancel</Button>
      </div>
    </div>
  )
};

export default CardsList;