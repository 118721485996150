import React from 'react';
import { SelectInput as RaSelectInput } from 'react-admin';
import otherConfig from '../../../config';

const getAddresses = (userId) => {
  const url = `${otherConfig.api}/users/${userId}/addresses`;

  const headers = new Headers({
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'Access-Control-Allow-Origin': '*',
  });

  const authToken = localStorage.getItem('authToken');

  if (authToken) {
    headers.set('Authorization', `Bearer ${authToken}`);
  }

  return fetch(url, { method: 'GET', headers }).then((response) => {
    return response.json();
  });
};

export class SelectAddressInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = { addresses: [] };
  }

  componentDidMount() {
    const { userId } = this.props;
    getAddresses(userId).then((addresses) => {
      if (!addresses.length) {
        const { record } = this.props;
        if (record.address) {
          const address = { name: record.address, string: record.address };
          this.setState({ addresses: [address] });
        }
      } else {
        const addressesWithStrings = addresses.map((a) => {
          const string = `${a.country}, ${a.state}, ${a.city}, ${a.street}, ${a.zip}`;
          return { ...a, string };
        });
        this.setState({ addresses: addressesWithStrings });
      }
    });
  }

  render() {
    return (
      <RaSelectInput
        label='Ship to address'
        disabled={this.props.disabled}
        optionText='name'
        optionValue='string'
        source='address'
        choices={this.state.addresses}
      />
    );
  }
}

export default SelectAddressInput;
