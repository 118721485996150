import React, { useState } from 'react';
import { showNotification } from 'react-admin';
import { withRouter } from 'react-router';
import { push } from 'react-router-redux';
import { Field, change } from 'redux-form';
import { connect } from 'react-redux';
import Row from './Row';
import './styles.scss'
import IPRUploader from "./IPRUploader/IPRUploader";

function genArray(record) {
  if (record.data) {
    console.log(record.data.iprTeeth.length);
    if (record.data.iprTeeth.length === 24) {
      return record.data.iprTeeth;
    }
  }

  let arr = [];
  const numbers = [16, 15, 14, 13, 12, 11, 21, 22, 23, 24, 25, 26, 46, 45, 44, 43, 42, 41, 31, 32, 33, 34, 35, 36];

  for (let i = 0; i < 24; i++) {
    arr.push({
      id: i,
      number: numbers[i],
      leftSideStep: null,
      leftSideMm: null,
      rightSideStep: null,
      rightSideMm: null,
      isAttachment: false,
    })
  }
  return arr;
}

function IPR(props) {
  const [tooth, setTooth] = useState(genArray(props.record));
  props.input.onChange(tooth);

  return (
    <>
      <IPRUploader setTooth={setTooth} />
      <table className='ipr' style={{ marginTop: '20px', width: '75%' }}>
        <tr>
          <th className='ipr__header'>Tooth</th>
          <th className='ipr__header'>Distal Side</th>
          <th className='ipr__header'>Mesial Side</th>
          <th className='ipr__header'>Attachment</th>
        </tr>
        {
          tooth.map(item => (
            <Row
              id={item.id}
              item={item}
              setTooth={setTooth}
              tooth={tooth}
            />
          ))
        }
      </table>
    </>
  );
}
export default withRouter(connect(null, {
  showNotification,
  push,
  change
})(({ source, record }) => {

  return (<Field
    name={source}
    component={IPR}
    record={record}
  />);
}));
