
const style = {
  line: {
    borderBottom: 'none',
  },
  form: {
    display: 'flex',
    flex: 'inherit',
    borderBottom: 'none',
    flexWrap: 'wrap'
  },
  root: {
    width: 'auto'
  },
  MarginNone: {
    marginTop: '0px',
  },
  EmailContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    maxWidth: '400px'
  }
};

export default style;