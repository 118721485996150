import React, { Component, useState } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import styles from './styles';
import config from '../../../config/';
import fileImg from '../../../img/file.png';
import { imgExtensions } from '../constants';

const checkIfImage = fileName => {

  const fileNameChunks = fileName.split('.');
  return fileNameChunks.length > 1 && 
    imgExtensions.indexOf(fileNameChunks[fileNameChunks.length - 1].toLowerCase()) !== -1;
};

const FileItem = ({
  file,
  showBiggerImg,
  classes,
  disabled,
  deleteFile
}) => {
  const serverUrl = `${config.api}/s3/uploads/${file.url}`
  const isImage = checkIfImage(serverUrl);
  return (
    <>
      <div className={classes.fileLink} >
        <div
          className={classes.fileImg}
          onClick ={_ =>  isImage ? showBiggerImg(serverUrl) : null}
          style={{'backgroundImage': `url(${isImage? serverUrl : fileImg})`}}>
        </div>
        <div className={classes.fileName}>{file.name}</div>
      </div>
      
      <div className={classes.rightBlock}>
        <a href={serverUrl} style={{textDecoration: 'none'}}>
          <Button className={classes.download} onClick={ _ => {console.log('get')}}>
            <span>Download</span>
          </Button>
        </a>
          
        {!disabled && 
          <Button className={classes.delete} onClick={ _ => deleteFile(file.name)}>
            <span>Delete</span>
          </Button>
        }
      </div>
    </>
  )
}

const FileArray = ({
  files,
  deleteFile,
  disabled,
  classes
}) => {
  const [ gallery, setGallery ] = useState(false);
  const [ galleryImg, setGalleryImg ] = useState(false);

  const showBiggerImg = url => {
    setGallery(!gallery);
    setGalleryImg(url);
  }

  return (files.length > 0) ?
    <>
      <ul className={classes.addedFiles}>
        {
          files.map((file, i) => 
            <li key={`f${i}`} className={classes.fileItem}>
              <FileItem
                file={file}
                showBiggerImg={showBiggerImg}
                classes={classes}
                disabled={disabled}
                deleteFile={deleteFile}
                />
            </li>
          )
        }
      </ul>
      {gallery && (
        <Lightbox
          mainSrc={galleryImg}
          onCloseRequest={() => setGallery(false)}
        />
      )}
    </> :
    <div className={classes.empty}> No Files</div>
}

class FileUploader extends Component {
  constructor(props) {
    super(props);
    this.uploadInput = React.createRef();
  }

  state = {
    fileList: [],
    btnLabel: '',
    loading: false,
    percent: 0
  }

  componentDidMount() {
    this.setState({
      fileList: this.getFilesFromProps(),
      btnLabel: this.handleButtonName(),
    }) 
  }

  getFilesFromProps = _ => {
    const {input, record} = this.props;
    if(input) {
      return input.value || [];
    }
    if(record) {
      return record.media || [];
    }
    return [];
  }

  handleButtonName() {
    const { fileList } = this.state;

    return fileList.length ? 
    `${fileList.length} Files added, add More` :
    'Add File'
  }

  deleteFile = (fileName) => {
    const newFileList = this.state.fileList.filter(file => file.name !== fileName);
    this.props.input.onChange(newFileList);
    this.setState({fileList: newFileList});
  }

  render() {
    const { classes, disabled } = this.props;
    const { fileList, btnLabel, loading, percent } = this.state;
    const folderPath = this.props.s3Folder || this.props.record.s3Folder;
    
    const onFinishFileUpload = result => {

      const getFileList = _ => this.state.fileList;
      const newFile = { name: result.filename, url : result.fileKey };
      const newFileList = [...getFileList(), newFile];
      this.setState({ fileList: newFileList });
      this.props.input.onChange(newFileList);
    }
  
    const onFileProgress = percentage => {
      if(percentage === 0) {
        return this.setState({loading: true})
      }
      if(percentage === 100) {
        return this.setState({loading: false})
      }
      this.setState({ percent: percentage })
    }
  
    const uploadOptions = {
      signingUrl: `/s3/sign`,
      signingUrlMethod:"GET",
      accept:"*/*",
      s3path: `cases/${folderPath}/`,
      onFinish: onFinishFileUpload,
      onProgress: onFileProgress,
      uploadRequestHeaders: { 'x-amz-acl': 'public-read' },
      signingUrlWithCredentials: false,
      signingUrlQueryParams: {uploadType: 'avatar'},
      server: `${config.api}`,
      autoUpload: true,
      className: classes.fileInput,
      id: 'filesInput',
      ref: this.uploadInput,
      multiple: true
    }

    return(
      <div className={classes.wrapper}>
      {
        !disabled && 
          <Button className={classes.labelFileInput} color='primary' >
            <label
              htmlFor="filesInput"
              style= {{padding: '3px 10px', display: 'inline-block'}}
            >
              {btnLabel}
            </label>
          </Button>
      }
      <ReactS3Uploader {...uploadOptions} />
      {
        !!fileList.length &&
          <a href={`${config.api}/s3/uploads/cases/${folderPath}/zip`}>
            <Button className={classes.zippedFiles} color='primary' >
              Download All
            </Button>
          </a>
      }
      <FileArray
        files={fileList}
        deleteFile={this.deleteFile}
        classes={classes}
        disabled ={disabled}
      />
      {
        loading &&
          <div className={classes.loadingWrapper}>
            <div className={classes.percent}>{percent}%</div>
            <div className={classes.loading}></div>
          </div>
    }
    </div>
    )
  }
}

const StyledFileUploader = withStyles(styles)(FileUploader);

export default ({ record, ...props }) => (
  <Field
    name="media"
    component={StyledFileUploader}
    record={record}
    {...props}
  />
)
