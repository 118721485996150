import React from 'react';
import { Field } from 'redux-form';
import { withStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import FormLabel from '@material-ui/core/FormLabel';
import ControlLabel from '../ControlLabel/';
import styles from './styles';

const RadioGroup = withStyles(styles.radioGroup)(({
  classes,
  input,
  options,
  label,
  className,
  disabled,
}) => {
  const onChange = e => input.onChange(e.target.value);
  const classN = className || classes.root;
  return (
    <div className={classN}>
      <FormLabel className={classes.formLabel} component="legend">{label}</FormLabel>
      {
        options.map(item =>
          <ControlLabel
            value={item.id}
            label={item.name}
            labelPlacement="end"
            onChange={onChange}
            key={item.id}
            control={<Radio disabled={disabled} color="primary" checked={input.value === item.id} />}
            className={classes.group}
          />
        )}
    </div>
  )
});

export default RadioGroup;
export const RadioGroupField = ({ source, ...props }) => <Field name={source} component={RadioGroup} {...props} /> 