import React from 'react';
import TextField from '@material-ui/core/TextField';
import DialogContentText from '@material-ui/core/DialogContentText';

const TrackNum = ({ trackNum, setTrackNum }) => (
  <>
    <DialogContentText>
      Insert your track number:
    </DialogContentText>
    <TextField
      autoFocus
      margin="dense"
      id="trackNum"
      label="Track Number"
      type="text"
      value={trackNum}
      onChange={(e) => setTrackNum(e.target.value)}
      fullWidth
    />
  </>
);

export default TrackNum;