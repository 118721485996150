const cardBase = {
  minWidth: '320px',
  borderRadius: "5px",
  padding: "10px",
  margin: "10px 0",
  display: "flex",
  justifyContent: "space-between",
  transition: "0.3s",
  cursor: "pointer",
  color: "#84C5E7",
}

const style = {
  SelectedCard: {
    ...cardBase,
    backgroundColor: "#eee"
  },
  Card: {
    ...cardBase,

    '&:hover': {
      backgroundColor: "#f5f5f5"
    }
  },

  CardNumber: {
    fontSize: 15,
    color: "#002D5A"
  },

  CardMonth: {
    "&:after": {
      content: "'/'"
    }
  },

  CardYear: {
    color: "#002D5A"
  },
}

export default style;