import React from 'react';
import { Field } from 'redux-form';
import Checkbox from '@material-ui/core/Checkbox';
import FormLabel from '@material-ui/core/FormLabel';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import * as consts from '../constants';
import ControlLabel from '../ControlLabel/';
import styles from './styles';
import defaultCase from '../../../utils/defaultCase';
import { getIfExists } from '../../../utils';

const setClasses = (classes, i) => {
  const classArr = [];
  if (i < 2) {
    classArr.push(classes.bottomBorder);
  }
  if ((i % 2) === 0) {
    classArr.push(classes.rightBorder);
  }

  return classArr;
}

const checkedBoxes = (teethArray, item) => {
  if (process.env.REACT_APP_THEME !== 'health') {
    return undefined;
  } else {
    return teethArray.indexOf(item) !== -1;
  }
}

const TeethCheckBox = withStyles(styles.checkBox)(({
  item,
  teethChange,
  labelPlacement,
  teethArray = [],
  classes,
  disabled
}) => (
    <ControlLabel
      labelPlacement={labelPlacement}
      control={
        <Checkbox
          checked={disabled ? (teethArray.indexOf(item) !== -1) : checkedBoxes(teethArray, item)}
          onChange={teethChange}
          value={item}
          className={classNames(classes.root)}
          disabled={disabled}
        />
      }
      label={item}
    />
  ));

const TeethGroup = withStyles(styles)(({
  classes,
  label,
  input,
  source,
  record = defaultCase,
  disabled
}) => {
  const { teethBlocks } = consts;

  const getTeethFromSource = _ => {
    if (input) {
      return input.value;
    }
    let teethSource = getIfExists(source, record);

    return teethSource || [];
  }

  let teethArray = getTeethFromSource();

  const onTeethChange = (e, val) => {
    const teethState = teethArray || [];
    const curentTeeth = e.target.value;
    if (input) {
      input.onChange(
        val ?
          [...teethState, curentTeeth] :
          teethState.filter(teeth => teeth !== curentTeeth)
      )
    }
  }

  return (
    <div className={classes.root}>
      <FormLabel component="legend">{label}</FormLabel>
      <div className={classes.TeethGroup}>
        {teethBlocks.map((block, i) => {
          return (
            <div
              key={`block${i}`}
              className={classNames(classes.TeethBlock, setClasses(classes, i))}
            >
              {
                block.map(
                  teeth => (
                    <TeethCheckBox
                      item={`${teeth}`}
                      labelPlacement={(i < 2) ? 'top' : 'bottom'}
                      teethChange={onTeethChange}
                      key={teeth}
                      teethArray={teethArray}
                      disabled={disabled}
                    />
                  )
                )
              }
            </div>
          )
        })}
      </div>
    </div>
  )
});

export default ({
  source,
  disabled,
  ...rest
}) => (
    disabled ?
      <TeethGroup source={source} disabled={disabled} {...rest} /> :
      <Field name={source} component={TeethGroup} {...rest} />
  );