const styles = _ => ({
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    verticalAlign: 'middle',
    cursor: 'pointer',
  },
  label: {
    color: 'rgba(0, 0, 0, 0.87)',
    fontSize: '0.875rem',
    fontWeight: 400,
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    lineHeight: '1.46429em'
  },
  left: {
    flexDirection: 'row-reverse',
    marginRight: -14,
    },
    /* Styles applied to the root element if `labelPlacement="top"`. */
    labelPlacementTop: {
      flexDirection: 'column-reverse',
    },
    /* Styles applied to the root element if `labelPlacement="bottom"`. */
    labelPlacementBottom: {
      flexDirection: 'column',
    },
});
export default styles;