import { AUTH_LOGIN, AUTH_LOGOUT, AUTH_ERROR, AUTH_GET_PERMISSIONS, AUTH_CHECK } from 'react-admin';
import decodeJwt from 'jwt-decode';
import config from '../config/';

const logOut = () => {
  localStorage.removeItem('authToken');
  localStorage.removeItem('roles');
  localStorage.removeItem('user');
  localStorage.removeItem('usrId');
}

export default async (type, params) => {
  // called when the user attempts to log in
  if (type === AUTH_LOGIN) {
    const { username, password } = params;
    const options = {
      method: 'POST',
      body: JSON.stringify({ email: username, password }),
      headers: new Headers({ 'Content-Type': 'application/json' }),
    }

    const serverResponse = await fetch(`${config.api}/${config.routes.auth}`, options);

    if (serverResponse.status < 200 || serverResponse.status >= 300) {
      throw new Error(serverResponse.statusText);
    }
    const token = await serverResponse.text();
    const decodedJwt = decodeJwt(token);
    localStorage.setItem('authToken', token);
    localStorage.setItem('roles', decodedJwt.roles);
    localStorage.setItem('user', `${decodedJwt.firstName} ${decodedJwt.lastName}`);
    localStorage.setItem('usrId', decodedJwt.id);

    return Promise.resolve();
  }
  // called when the user clicks on the logout button
  if (type === AUTH_LOGOUT) {
    logOut();
    return Promise.resolve();
  }
  // called when the API returns an error
  if (type === AUTH_ERROR) {
    const { status } = params;
    if (status >= 400) {
      logOut();
      return Promise.reject();
    }
    return Promise.resolve();
  }
  // called when the user navigates to a new location
  if (type === AUTH_CHECK) {
    return localStorage.getItem('authToken')
      ? Promise.resolve()
      : Promise.reject();
  }

  if (type === AUTH_GET_PERMISSIONS) {
    const roles = localStorage.getItem('roles');
    return roles ? Promise.resolve(roles) : Promise.reject();
  }

  return Promise.reject('Unknown method');
};