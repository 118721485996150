import React, { useState } from 'react';
import DialogContentText from '@material-ui/core/DialogContentText';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';

const Replacement = ({ shipmentType, setShipmentType, comment, setComment }) => {

  const handleChange = e => setShipmentType(e.target.value);

  return (
    <>
      <DialogContentText> Select product type: </DialogContentText>
      <Select value={shipmentType} onChange={handleChange} style={{ width: '200px', marginBottom: '20px' }}>
        {['ALIGNER', 'RETAINER'].map((shipment, i) => (<MenuItem value={shipment} key={i} onClick={() => setShipmentType(shipment)}>{`${shipment}`}</MenuItem>))}
      </Select>

      <DialogContentText>Type your comment there: </DialogContentText>
      <TextField
        style={{ width: '400px', marginTop: '-5px' }}
        label="Your comment"
        multiline
        rowsMax="4"
        value={comment}
        onChange={e => setComment(e.target.value)}
        margin="normal"
      />
    </>
  );
}

export default Replacement;