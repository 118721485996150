import * as consts from './constants';

const statuses = consts.caseStatuses;

// returns transitions aray that are available for actual status
// on base of that are genereated buttons for changing status in the top of case
// eg: from CURRENT_STATE -> you can go to [ STATE_1, STATE_2 ]
const stateTransitions = (status, roles) => {
  const transitions = {
    [statuses[0].id]: [statuses[1]], // NEW -> [ CREATE_PLAN ]
    [statuses[1].id]: [statuses[2], statuses[3]], // CREATE_PLAN -> [ PLAN_AVAILABLE, REFINE_SETUP ]
    [statuses[2].id]: [statuses[4], statuses[5]], // PLAN_AVAILABLE -> [ PRODUCE_ALIGNER, IMPROVE_SETUP ]
    [statuses[3].id]: [statuses[1], statuses[11]], // REFINE_SETUP -> [ CREATE_PLAN, NOT_SUITABLE ]
    [statuses[5].id]: [statuses[3], statuses[2]], // IMPROVE_SETUP -> [ REFINE_SETUP, PLAN_AVAILABLE ]
    [statuses[4].id]: [statuses[6]], // PRODUCE_ALIGNER -> [ ALIGNER_SHIPPED ]
    [statuses[6].id]: [statuses[7], statuses[8], statuses[12]], // ALIGNER_SHIPPED -> [ ARCHIVED, PRODUCE_RETAINER, REPLACEMENT_REQUIRED ]
    [statuses[7].id]: [statuses[12], statuses[8], statuses[5]], // ARCHIVED -> [ REPLACEMENT_REQUIRED, PLAN_AVAILABLE, IMPROVE_SETUP ]
    [statuses[8].id]: [statuses[9]], // PRODUCE_RETAINER -> [ RETAINER_SHIPED ]
    [statuses[9].id]: [statuses[7], statuses[12]], // RETAINER_SHIPED -> [ ARCHIVED, REPLACEMENT_REQUIRED ]
    [statuses[11].id]: [statuses[7]], // NOT_SUIABLE -> [ ARCHIVED, REPLACEMENT_REQUIRED ],
    [statuses[12].id]: [statuses[13]], // REPLACEMENT_REQUIRED -> [ REPLACEMENT_PLAN_AVAILABLE ]
    [statuses[13].id]: [statuses[14]], // REPLACEMENT_PLAN_AVAILABLE -> [ PRODUCE_REPLACEMENTS ]
    [statuses[14].id]: [statuses[15]], // PRODUCE_REPLACEMENTS -> [ REPLACEMENTS_SHIPPED ]
    [statuses[15].id]: [statuses[7], statuses[12], statuses[8]] // REPLACEMENTS_SHIPPED -> [ ARCHIVED, REPLACEMENT_REQUIRED, PRODUCE_RETAINER ]
  };

  return transitions[status];
};

export const SubmmitterStatuses = [
  statuses[0].id,
  statuses[2].id,
  statuses[3].id,
  statuses[6].id,
  statuses[7].id,
  statuses[9].id,
  statuses[10].id,
  statuses[11].id,
  statuses[13].id,
  statuses[15].id,
];

export const EditorStatuses = [
  statuses[1].id,
  statuses[4].id,
  statuses[5].id,
  statuses[7].id,
  statuses[8].id,
  statuses[12].id,
  statuses[14].id,
];

export default stateTransitions;