import React, { useState, useEffect } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Tooth from './Tooth';
import tooth1 from './images/tooth1.svg';
import tooth2 from './images/tooth2.svg';
import tooth3 from './images/tooth3.svg';
import tooth4 from './images/tooth4.svg';
import tooth5 from './images/tooth5.svg';
import tooth6 from './images/tooth6.svg';
import tooth7 from './images/tooth7.svg';
import tooth8 from './images/tooth8.svg';
import tooth9 from './images/tooth9.svg';
import tooth10 from './images/tooth10.svg';
import tooth11 from './images/tooth11.svg';
import tooth12 from './images/tooth12.svg';
import tooth13 from './images/tooth13.svg';
import tooth14 from './images/tooth14.svg';
import tooth15 from './images/tooth15.svg';
import tooth16 from './images/tooth16.svg';
import tooth17 from './images/tooth17.svg';
import tooth18 from './images/tooth18.svg';
import tooth19 from './images/tooth19.svg';
import tooth20 from './images/tooth20.svg';
import tooth21 from './images/tooth21.svg';
import tooth22 from './images/tooth22.svg';
import tooth23 from './images/tooth23.svg';
import tooth24 from './images/tooth24.svg';

const Title = ({ title }) => <FormLabel style={{
  color: 'rgb(193, 88, 220)',
  margin: '50px 0 25px',
  fontSize: '25px',
}} component="legend">{title}</FormLabel>

export default function IprWidget({ record }) {
  const data = record.data.iprTeeth;
  const [isValid, setIsValid] = useState(false);
  const [title, setTitle] = useState('No IPR & No Attachments');

  useEffect(() => {
    const el = data.find(({
      leftSideStep,
      leftSideMm,
      rightSideStep,
      rightSideMm,
      isAttachment,
    }) => {
      const valid =
        leftSideMm ||
        leftSideStep ||
        rightSideStep ||
        rightSideMm ||
        isAttachment;

      return valid;
    });

    if (el) {
      const ipr = data.find(({
        leftSideStep,
        leftSideMm,
        rightSideStep,
        rightSideMm,
        isAttachment,
      }) => {
        const valid =
          leftSideMm ||
          leftSideStep ||
          rightSideStep ||
          rightSideMm;

        return valid;
      });
      const attach = data.find(({
        isAttachment,
      }) => {
        return isAttachment;
      });

      let title = '';
      if (ipr) {
        title += 'IPR & ';
      } else {
        title += 'No IPR & ';
      }

      if (attach) {
        title += 'Attachments';
      } else {
        title += 'No Attachments';
      }

      setTitle(title);
      setIsValid(true);
    }
  }, []);

  if (!isValid) {
    return <Title title={title}></Title>
  }

  return (
    <>
      {<Title title={title}></Title>}
      <div className="tooth__top">
        <Tooth
          img={tooth1}
          data={data[0]}
          number={16}
          next={data[1]}
        />

        <Tooth
          img={tooth2}
          data={data[1]}
          number={15}
          next={data[2]}
        />

        <Tooth
          img={tooth3}
          data={data[2]}
          number={14}
          next={data[3]}
        />

        <Tooth
          img={tooth4}
          data={data[3]}
          number={13}
          next={data[4]}
        />

        <Tooth
          img={tooth5}
          data={data[4]}
          number={12}
          next={data[5]}
        />

        <Tooth
          img={tooth6}
          data={data[5]}
          number={11}
          next={data[6]}
        />

        <Tooth
          img={tooth7}
          data={data[6]}
          number={21}
          next={data[7]}
        />

        <Tooth
          img={tooth8}
          data={data[7]}
          number={22}
          next={data[8]}
        />

        <Tooth
          img={tooth9}
          data={data[8]}
          number={23}
          next={data[9]}
        />

        <Tooth
          img={tooth10}
          data={data[9]}
          number={24}
          next={data[10]}
        />

        <Tooth
          img={tooth11}
          data={data[10]}
          number={25}
          next={data[11]}
        />

        <Tooth
          img={tooth12}
          data={data[11]}
          number={26}
        />
      </div>

      <div className="tooth__top">
        <Tooth
          img={tooth13}
          data={data[12]}
          number={46}
          next={data[13]}
        />

        <Tooth
          img={tooth14}
          data={data[13]}
          number={45}
          next={data[14]}
        />

        <Tooth
          img={tooth15}
          data={data[14]}
          number={44}
          next={data[15]}
        />

        <Tooth
          img={tooth16}
          data={data[15]}
          number={43}
          next={data[16]}
        />

        <Tooth
          img={tooth17}
          data={data[16]}
          number={42}
          next={data[17]}
        />

        <Tooth
          img={tooth18}
          data={data[17]}
          number={41}
          next={data[18]}
        />

        <Tooth
          img={tooth19}
          data={data[18]}
          number={31}
          next={data[19]}
        />

        <Tooth
          img={tooth20}
          data={data[19]}
          number={32}
          next={data[20]}
        />

        <Tooth
          img={tooth21}
          data={data[20]}
          number={33}
          next={data[21]}
        />

        <Tooth
          img={tooth22}
          data={data[21]}
          number={34}
          next={data[22]}
        />

        <Tooth
          img={tooth23}
          data={data[22]}
          number={35}
          next={data[23]}
        />

        <Tooth
          img={tooth24}
          data={data[23]}
          number={36}
        />
      </div>
    </>
  )
}
