export const getPermissions = permissions => {
  if (!permissions) {
    return null;
  }
  const rights = permissions.split(',');
  return (rights.length > 1 || rights.indexOf('ADMIN') !== -1 || rights.indexOf('PROVIDER') !== -1) ? 'ALL' : rights[0];
}

export const hasPermission = (permissions, permission) => {
  return permissions && permissions.indexOf(permission) > -1;
}