const styles = {
  root: {
    marginTop: '20px'
  },
  TeethGroup: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '15px',
  },
  TeethBlock: {
    width: '50%',
    display: 'flex',
    paddingTop: '10px',
    paddingLeft: '10px',
    boxSizing: 'border-box',
    justifyContent: 'space-between',
  },
  rightBorder: {
    paddingLeft: '0',
    paddingRight: '10px',
    borderRight: '1px solid #999',
  },
  bottomBorder: {
    paddingTop: '0',
    paddingBottom: '10px',
    boxShadow: ' 0 1px 0 #999'
  },
  checkBox: {
    root: {
      padding: 0,
      width: 'auto'
    }
  },
  [`@media (max-width: 1200px)`]: {
    TeethGroup: {
      width: '100%'
    }
  },
}

export default styles;