import React, { useState, useEffect } from 'react';
import FormLabel from '@material-ui/core/FormLabel';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

export const checkFor3DRender = (message) => {
  if (message.indexOf('https://dentadynamics.com') !== -1) {
    const links = message.match(/https:\/\/dentadynamics\.com[^ ]*\b/g);

    return links[0];
  }

  return null;
}


const Title = ({ title }) => <FormLabel style={{
  color: 'rgb(193, 88, 220)',
  margin: '50px 0 25px',
  fontSize: '25px',
}} component="legend">{title}</FormLabel>


const DentaDynamics = ({ record, title, setIsDisabled }) => {
  const [links, setLinks] = useState([]);
  const [current, setCurrent] = useState(links[0]);

  useEffect(() => {
    const found = record.chat
      .map((item) => checkFor3DRender(item.message))
      .filter((item) => item !== null);

    setLinks(found);

    if (found.length) {
      setCurrent(found[found.length - 1]);
      setIsDisabled(true);
    }
  }, []);

  const handleChange = (e) => {
    setCurrent(e.target.value);
  }

  if (!links.length) {
    return <Title title="No TP Attachments"></Title>;
  }

  return (<>
    {links.length === 1 ? title : (
      <Select
        value={current}
        onChange={handleChange}
        style={{
          color: '#002D5A',
          margin: '50px 0 25px',
          fontSize: '25px',
        }}
      >
        {links.map((link, idx) => (<MenuItem value={link}>TP {idx + 1}</MenuItem>))}
      </Select>
    )}
    <iframe className='iprIframe' title={current} src={current}></iframe>

  </>);
}

export default DentaDynamics;
